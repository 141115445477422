/* body {
  background: #efefef;
} */

.wrapper {
  margin-top: 80px;
  margin-bottom: 80px;
}

.form-signin {
  max-width: 380px;
  padding: 15px 35px 45px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 3rem 5rem -2rem rgba(0, 0, 0, 0.2);
}

.form-control {
  font-size: 16px;
  padding: 10px;
  box-sizing: border-box;
  height: auto;
  border-radius: 1px;
}

.authbutton {
    background-color: #056174;
    border-color: #056174;
    width: 100%;
}

/* enable absolute positioning */
.inner-addon {
  position: relative;
}

/* style icon */
.inner-addon .glyphicon {
  position: absolute;
  top: 10px;
  padding: 10px;
  font-size: xx-large;
  pointer-events: none;
}

/* align icon */
.left-addon .glyphicon {
  left: 0px;
}

/* add padding */
.left-addon input {
  padding-left: 65px;
}

.form-signin input {
  margin-bottom: 15px;
  height: 44px;
}

.form-signin .forgot-password {
  display: block;
  padding: 2px 0;
  margin-top: -8px;
  margin-bottom: 30px;
  font-size: 12px;
}

.account-signup {
  max-width: 380px;
  padding: 15px 35px;
  margin: 0 auto;
  background-color: #056174;
}
